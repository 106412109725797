<template>
  <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
    <template #title>
      <a-row type="flex" align="middle">
        <a-col :span="24" :md="12">
          <h5 class="font-semibold m-0">打新任务列表</h5>
        </a-col>
        <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
          <a-button type="primary" icon="plus" size="small" @click="addtask()">
            创建打新任务
          </a-button>
        </a-col>
      </a-row>
    </template>
    <a-table :columns="columns" :data-source="tableData" :pagination="{pageSize: 10,}">


      <template slot="task_id" slot-scope="task_id">
        {{ task_id }}
      </template>

      <template slot="exchange" slot-scope="exchange">
        <div class="table-avatar-info">
          <a-avatar shape="square" :src="exchange" style="margin-right: 0;"/>
        </div>
      </template>

      <template slot="api_name" slot-scope="api_name">
        {{ api_name }}
      </template>

      <template slot="investment" slot-scope="investment">
        {{ investment }}
      </template>

      <template slot="status" slot-scope="status">
        <a-tag
            class="tag-status"
            :class="status === 1 ? 'ant-tag-success' : 'ant-tag-muted'"
        >
          {{ status === 1 ? "进行中" : "结束" }}
        </a-tag>
      </template>

      <template slot="editBtn" slot-scope="row">
        <a-button type="link" :data-id="row.id" class="btn-edit" @click="detail(row.task_id)">
          查看详情
        </a-button>
      </template>

      <template slot="stopBtn" slot-scope="row">
        <a-button type="link" :data-id="row.id" class="btn-edit" @click="stop(row.task_id)">
                  {{ row.status === 1 ? "停止打新" : "" }}
        </a-button>
      </template>

    </a-table>

  </a-card>
</template>


<script>
import http from "../utils/http"

const key = 'updatable';

const columns = [
  {
    title: '任务ID',
    dataIndex: 'task_id',
    scopedSlots: {customRender: 'task_id'},
  },
  {
    title: '交易所',
    dataIndex: 'exchange',
    scopedSlots: {customRender: 'exchange'},
  },
  {
    title: 'API名称',
    dataIndex: 'api_name',
    scopedSlots: {customRender: 'api_name'},
  },
  {
    title: '投资金额',
    dataIndex: 'investment',
    scopedSlots: {customRender: 'investment'},
  },
  {
    title: '创建时间',
    dataIndex: 'create_datetime',
    class: 'text-muted',
  },
  {
    title: '',
    scopedSlots: {customRender: 'editBtn'},
    width: 50,
  },
  {
    title: '',
    scopedSlots: {customRender: 'stopBtn'},
    width: 50,
  },
];


function transformItem(item) {
  let imagePath;
  switch(item.exchange) {
    case 1:
      imagePath = 'images/okx.png';
      break;
    case 2:
      imagePath = 'images/binance.png';
      break;
      // 可以添加更多的条件
    default:
      imagePath = 'images/face-3.jpeg'; // 默认图片
  }
  return {
    id: String(item.id), // 使用项目的 'id' 字段
    exchange: imagePath,
    task_id: item.task_id,
    follow_type: item.follow_type,
    api_name: item.api_name,
    investment: item.investment,
    status: item.status,
    create_datetime: item.create_datetime,
  };
}


export default {
  data() {
    return {
      tableData: [],
      columns,


    };
  },
  methods: {
    async fetchData() {
      try {
        const token = localStorage.getItem("token");
        const response = await http.get("/api/ico/?token=" + token);
        const originalData = response.data.data;
        const tableData = originalData.map(item => transformItem(item));
        this.tableData = tableData; // 更新组件的数据属性
        console.log(response.data.data); // 现在这里将正确打印出数据
      } catch (error) {
        console.error("请求失败:", error);
      }
    },

    // 终止任务
    stop(id) {
      console.log(id);
      this.$confirm({
          title: '确认终止',
          content: '您确定要终止抢跟单席位吗？',
          okText: '确认',
          cancelText: '取消',
          onOk: () => {
            return new Promise((resolve, reject) => {
              http.patch("/api/ico/?token=" + localStorage.getItem("token"), {id: id})
                  .then(() => {
                    this.fetchData(); // 删除成功后调用 fetchData
                    resolve();
                  })
                  .catch(err => {
                    reject(err); // 错误处理
                  });
            });
          },
          onCancel() {
            console.log('删除操作取消');
          },
        });
      },

    addtask() {
      this.$router.push({path: '/ico-task'});
    },

    detail(id) {
      // 导航到 ICO_details 页面
      this.$router.push({ name: '打新交易详情', params: { id } });
    },

  },
  mounted() {
    this.fetchData();
  }
};
</script>

<style lang="scss">
.ant-table-pagination.ant-pagination {
  margin-right: 25px;
  margin-left: 25px;
}
</style>